<template>
    <b-card :footer-class="!card_expanded ? 'no-footer' : ''" no-body class="newTile m-2" :style="{...propBackground}" :class="[getSelectedClass, expandedItemClasses]" >
        <template #header class="cursor-pointer pt-1" @click="selectItem(item)">
            <div class="d-flex">
                <span :class="getCustomClasses" style="padding-top: 0.06rem;">
                    
                <RadialProgressBar class="mt-1"  v-if="hasProgressField" :percentage="getClampedProgress"/>
                    
                </span>
                <strong class="sub-heading-text cursor-pointer pt-1" @click="selectItem(item)" >
                    <span  style="display: block;" class="title-text-header">
                        <b-badge v-if="item.category" class="bg-primary" style="position: relative; top: -1.5px;">
                            {{item.category}}
                        </b-badge>
                        {{ item.name}}
                    </span>
                </strong>

                <div class="d-inline-flex ms-auto">
                    <div v-if="hasOwnerUserId" class="d-flex ps-1">
                        <img :src="getImgSrc(item)" alt="" class="cursor-pointer avatar-xxs rounded-circle " v-b-tooltip.hover.left :title="ownerName">
                    </div>
                </div>
                
            </div>
        </template>
        
                <transition name="fade">
                <component v-if="card_expanded && dynamicComponent != null" :is="dynamicComponent" :item="item"></component>
                </transition>
                <!-- progress bar only visible if progress property exists -->

                <div :style="fillBackgroundColour" class="d-flex h-100" ></div><!-- style="align-self: flex-end;" -->

                <transition name="fade">
                <div class="px-2 d-flex" v-if="card_expanded && hasProgressField">
                    <span class="small-text">Progress:</span>
                    <span class="ms-auto">{{ getClampedProgress }}%</span>
                </div>
                </transition>

                
                <transition name="fade">
                <div class="d-flex px-2 pb-1" v-if="card_expanded && hasProgressField">
                    
                    <div class="tc-progress">
                        <!-- <div class="tc-progress-bar" role="progressbar" :style="`width: ${{getClampedProgress}}%;`" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div> -->
                        <b-progress height="10px" class="flex-grow-1 modify-progress-bar" :value="getClampedProgress" max=100></b-progress>
                    </div>
                </div>
            </transition>
        <!-- </b-collapse> -->
        
        
        <template #footer>
            <transition name="fade" >
            <div class="d-flex" v-if="card_expanded">
                <span v-if="hasNote">
                    <font-awesome-icon v-b-popover.hover="getPopoverContent" v-if="getNote" @click="showNote($event)" class=" icon-margin-fix icon-pointer" icon="fa-comment-dots"></font-awesome-icon>
                    <font-awesome-icon  v-else @click="showNote($event)" class="text-secondary icon-margin-fix icon-pointer" icon="fa-comment"></font-awesome-icon>
                </span>
                <span v-if="hasNote" class="ps-1 font-size-10">
                    <span v-if="getNote == ''">No comment</span>
                </span>
                <b-button v-if="has_nested_data" :disabled="childrenLength == 0" :variant="isCurrentItemInExpandedList ? 'primary' :'light'" size="sm" @click="toggleChildren($event)" class="w-sm pt-0 pb-0 ms-auto" >
                        <span v-show="childrenLength > 0" > Children ({{ childrenLength }})</span>
                        <span v-show="childrenLength == 0" > Children (0)</span>

                        <font-awesome-icon v-show="!isCurrentItemInExpandedList && childrenLength > 0"  class="fa-fw" icon="fa-caret-down"></font-awesome-icon>
                        <font-awesome-icon v-show="isCurrentItemInExpandedList && childrenLength > 0" class="fa-fw" icon="fa-caret-up"></font-awesome-icon>
                </b-button>
            </div>
        </transition>
        </template>
    </b-card>
</template>

<script>
import RadialProgressBar from './radialProgressBar.vue';
import { webixTableState, levelComputed, performanceComputed, performanceMethods } from '@/state/helpers'
import dateMixin from '@/mixins/dateMixin.js'
export default {
    name: "TileCard",
    mixins: [dateMixin],
    components:{
        RadialProgressBar,
    },
    props: {
        card_expanded:{
            default: false,
        },
        /*has_nested_data: {
            default: false,
        },*/
        badge_class:{
            default: '',
        },
        card_title_prefix:{
            default: '',
        },
        inner_card_component_name:{
            default: false,

        },
        item: {
            type: Object,
            required: true,
        },
        depth:{
            type: Number
        },
        expanded_items:{
            required: true,
        },
        webix_uid:{
            required: true,
        },
        tile_color:{
            default:function() {
                if(this.webix_uid == 'objectivesTable'){
                    return '#38aea2'
                }
                else if(this.webix_uid == 'keyResultsTable'){
                    return "#cf89d5"
                }
                else if(this.webix_uid == 'milestonesTable'){
                    return "#517af4"
                }
                else if(this.webix_uid == 'milestoneGoalsTable'){
                    return "#517af4"
                }
                else if(this.webix_uid == 'goalObjectivesTable'){
                    return "#517af4"
                }
                else{
                    return "#f4b651"
                }
            }
        },
        tile_color_bg:{
            default:function() {
                if(this.webix_uid == 'objectivesTable'){
                    return 'rgb(246 255 252)'
                }
                else if(this.webix_uid == 'keyResultsTable'){
                    return ""
                }
                
                else if(this.webix_uid == 'milestonesTable'){
                    return "rgb(236 241 255)"
                }
                else if(this.webix_uid == 'milestoneGoalsTable'){
                    return "rgb(235 248 255)"
                }
                else if(this.webix_uid == 'goalObjectivesTable'){
                    return "rgb(235 255 249)"
                }
                else{
                    return "#fff8ec"
                }
            }
        },
    },
    data:() => ({
        dynamicComponent: null,
        isHoveringOverCard: false,
        test: 0,
    }),
    computed:{
        ...levelComputed,
        ...webixTableState,
        ...performanceComputed,
        fillBackgroundColour(){
            return this.card_expanded ? '' : 'background-color: rgba(0, 0, 0, 0.03) !important;'
        },
        getPopoverContent(){
            return {
                customClass: 'datatype-popover-class',
                placement: 'auto',
                title: 'Comment',
                content: this.getNote,
            }
        },
        isCurrentItemInExpandedList(){
            let idx = this.expanded_items.findIndex( element => {
                return element.id == this.item.id;
            })
            return idx != -1;
        },
        expandedItemClasses(){
            return this.isCurrentItemInExpandedList ? 'breadcrumb_tile_class' : ''
        },


        ownerName(){
            if(!this.item.owner_name){
                return '';
            }
            if(this.item.owner_name != 'default'){
                return this.item.owner_name
            }
            else{
                let user = this.all_users.find( item => { return item.id == this.item.owner_user_id});
                if(user){
                    console.log('user', user);
                    return user.name
                }
                else{
                    return '';
                }
            }
        },
        hasOwnerUserId(){
            return this.item.owner_user_id != undefined;
        },
        hasNote(){
            if(this.item.notes === undefined){
                return false;
            }
            //* if the note property is undefined, assume that the item doesn't support notes
            return true;
        },
        getNote(){
            let note = this.item.notes;
            if(note == null){
                note = '';
            }
            return note;
        },
        getClampedProgress(){
            if(this.item.progress > 100){
                return 100;
            }
            return this.item.progress;
        },
        has_nested_data(){
            if(this.table_states[this.webix_uid] != undefined){
                return this.table_states[this.webix_uid].has_nested_data;
            }
            else{
                return false;
            }
        },
        selected_item_id(){
            if(this.active_card_ids[this.webix_uid] != undefined){
                return this.active_card_ids[this.webix_uid];
            }
            else{
                return -1;
            }
        },
        'propBackground'(){
            if(this.isCurrentItemSelected){
                return {    'border-color': `${this.tile_color} !important`,
                            'border-left': `4px solid ${this.tile_color}`,
                            'background-color': this.tile_color_bg,
                }
            }
            return {};
        },
        childrenLength(){
            if(this.item.data == undefined){
                return 0
            }

            return this.item.data.length;
        },
        getDateFormat(){
            let start = this.getSimpleDate(this.item.start_date, '/');
            //! do some hack to get the end date.. some items have deadline, some items have end_date
            let end = this.item.deadline ? this.item.deadline : this.item.end_date
            end = this.getSimpleDate(end, '/');
            return `${start} - ${end}`
            //return 'fix date format'
        },
        canShowCurrentCard(){
            //return true;
            if(this.hasParentSelected == false){
                // always show cards when no parent is selected
                return true;
            }
            else{
                //hide other cards except for the parent
                return this.item.id == this.expanded_items[this.depth].id;
            }
        },
        hasParentSelected(){
            //return false;
            return this.expanded_items[this.depth] != undefined;
        },
        showChildren(){
            return this.expanded_items.some(obj => obj.id == this.item.id);
            //return this.expanded_items.includes(this.item.id)
        },
        depthStyle(){
            return `depth${this.depth}`;
        },
        getStateClass(){
            let class_list = this.showChildren ? 'isFullWidth' : 'isTile card-hover';
            class_list += ` ${this.depthStyle}`;
            if(this.isCurrentItemSelected){
                class_list += ' card-tile-selection-border'
            }
            return class_list;
        },
        isCurrentItemSelected(){
            return this.item.id == this.selected_item_id;
        },
        getCustomClasses(){
            if(this.item.objective_type == 'personal') {
                return `badge-item badge-item-objective-personal`
            }
            if(this.badge_class != ''){
                return ` ${this.badge_class} `;
            }
            else{
                return '';
            }
        },
        getSelectedClass(){
            if(this.isCurrentItemSelected){
                return 'border-primary selected_tile';
            }
            else{
                return '';
            }
        },
        hasProgressField(){
            return this.item.progress != undefined;
        }
    },
    methods:{
        ...performanceMethods,
        //eslint-disable-next-line
        getImgSrc(item){
            let img_src = '/img/user.png';
            if(item.owner_user_id){
                let user_object = this.all_users.find( user => {
                    return user.id == item.owner_user_id;
                })
                if(user_object){
                    img_src = user_object.avatar_src;
                }
            }
            return img_src;
        },
        showNotesModal(event_data){
            this.$emit('showNotesModal', event_data);
        },
        showNote(event){          
            event.stopPropagation();
            let note_data = {
                id: this.item.id,
                notes: this.getNote,
                title: this.item.name,
            }
            this.$emit('showNotesModal', note_data);
        },
        toggleChildren(event) {
            event.stopPropagation();
            if(this.selected_goal_objective != -1 && this.selected_item_depth > 0) {
                console.log(this.selected_goal_objective)
                let table = window.webix.$$(this.webix_uid);
                if(table){
                    table.select(this.item.id);
                    this.setSelectedItemDepth(this.depth)
                    
                }
            }
            let payload = {
                depth_index: this.depth,
                item: {
                    id: this.item.id,
                    name: this.item.name
                }
            }
            this.$emit('handleToggleChild', payload);

            //this.expandObjectives(this.item.id)
            //this.showChildren = !this.showChildren;
        },
        handleToggleChild(payload){
            this.$emit('handleToggleChild', payload)
        },
        selectItem(item){
            console.log(item)
            console.log(this.depth);
            if(item.id == this.selected_item_id){
                this.$emit('resetSelection');
            }
            let table = window.webix.$$(this.webix_uid);
            if(table){
                let value = this.depth
                console.log(value)
                if(value == 0) {
                    let payload = {
                        depth_index: this.depth,
                        item: {
                            id: item.id,
                            name: item.name
                        }
                    }
                    this.handleToggleChild(payload)
                }
                this.setSelectedItemDepth(Number(value))
                table.select(item.id);
                
            }
            console.log(this.test)
            // if(this.selected_item_object && this.selected_item_object.id == item.id){
            //     this.setSelection(null)
            // }
            // else{
            //     this.setSelection(item);
            // }
        },
    },
    async created() {
        if(this.inner_card_component_name == false){
            return;
        }
        try {
            const module = await import(`@/components/objectives/components/tiles/inner/${this.inner_card_component_name}`);
            this.dynamicComponent = module.default;
        } catch (error) {
            console.error(error);
        }
    }
}
</script>

<style scoped>
.selected_tile .card-header{
    text-decoration: underline;
}
.selected_tile{
    
    box-shadow: 0px 0px 10px 0px rgba(0, 30, 255, 0.75);
}
/* .selected_tile_class{
    border: 1px solid #000231;
} */
.breadcrumb_tile_class{
    border: 1.5px dashed #000231;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 1);
    /* box-shadow: 0 4px 8px 0 rgba(8, 13, 83, 0.404), 0 6px 8px 0 rgba(13, 1, 91, 0.484);  */
}
.breadcrumb_tile_class .card-header{
    /* background-color: rgba(132, 132, 255, 0.215); */
}

.newTile:hover{
    box-shadow: 0px 1px 3px 0px rgb(0, 17, 255);
}
.newTile .card-header{
    padding-top:0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
.newTile .card-footer{
    padding-top:0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.tc-progress{
    height: 0.2rem;
    width: 100%;
    background: rgb(222, 222, 222);
}
.tc-progress-bar{
    height: 0.2rem;
    background: black;
}
.modify-progress-bar{
    padding: 0;
    margin: 0;
    font-size: 0;
    height: 0.2rem !important;
    /* background: black; */
}

.tc-one{
    width: 270px;
    background-color: #000;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.tc-pic1{
    position: relative;
    right: 22px;
    top: 23px;
}
.tc-pic2{
    position: relative;
    left: 25px;
    top: 17px;
}
.tc-pic3{
    position: relative;
    right: 13px;
    bottom: 18px;
}
.tc-pic4{
    position: relative;
    left: 14px;
    bottom: 21px;
}
.tc-project{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #fd4040;
}
.tc-quote{
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #fff;

}
.tc-two{
    width: 18rem;
    background-color: #fff;
    
    align-items: center;
    /* text-align: center; */
    border-radius: 10px;
    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);    
}
.tc-star{
    font-size: 20px !important;
    color: #b1b4b7;
}
.tc-dot{
    font-size: 20px !important;
    color: #b1b4b7;
}
.tc-round{
    border-radius: 50%;
    background-color: #eee;
    width: 33px;
    height: 33px;
    margin-bottom: -10px;
    align-items: center;
    justify-content: center;
}
.tc-name{
    font-size: 0.9rem;
    color: #464e56;
    /* font-weight: 600;
    text-align: left; */
}
.tc-quote2{
     font-size: 12px;
     font-weight: 500;
     color: #868e94;
     text-align: left;
}
.tc-img1{
    position: relative;
    left: 20px;
    z-index: 28;
    border: 0.5px solid #6ebde4;
    border-radius: 50%;
    background-color: #bcd8e6;
}
.tc-img2{
    position: relative;
    left: 10px;
    z-index: 29;
    border: 0.5px solid #6ebde4;
    border-radius: 50%;
    background-color: #bcd8e6;
}
.tc-img3{
    z-index: 30;  
    border: 0.5px solid #6ebde4;
    border-radius: 50%; 
    background-color: #bcd8e6;
}
.tc-task{
     color: #727475;
}
.tc-date{
     color: #727475;
}
.tc-imgfix{
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    top: 2px;
}
    .objective {
        cursor: pointer;
        /* Add desired styling */
    }
    .isTile:hover{
        
        border: 1px solid #a0a0a0;
    }
    .isTile{
        cursor: pointer;
        box-shadow: 0 0 0.5rem rgba(50, 50, 50, 0.2);
        width: 250px;
        /* height: 150px; */
        /*border: 1px solid #747474;
        border-radius: 0.15rem;*/
        margin: 0.3rem;
        /* padding: 0.7rem; */
        border: 1px solid #DADEE0;
        background-color: #ffffff;
        /* -- */
        -ms-flex-preferred-size: calc(33.33333% - 30px);
        flex-basis: calc(33.33333% - 30px);

    }
    .isFullWidth{
        width: 100%;
    }


    .tile-info .details {
        
  display: flex;
  gap: 1rem;
}
.tile-info .details div {
  padding: 0.1em 1  em;
  background-color: #f1eeff;
  border: 1px solid #DADEE0;
  display: flex;
  flex-direction: column;
  gap: 0.125em;
  flex-basis: 50%;
}
.tile-info .details dt {
  font-size: 0.4;
  color: #a69fd6;
}
.tile-info .details dd {
  color: #6558d3;
  font-weight: 600;
  font-size: 0.6rem;
}

    .progress-green-color>div{
        
        padding-top: 1px;
        background-color: #34c38f !important;
    } 
    .progress-green-color{
        margin-top: 2px;
    }

.card-tile-selection-border{
    /* border: 1px solid dodgerblue; 
    border: 1px solid black;*/
}

.two-line-wrap{
    display: -webkit-box; /* Required for Safari */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grey-date-tag{
    font-size: 0.6rem;
    background: rgba(228, 230, 240, 0.8);
    padding: 0 8px;
    border-radius: 12px;
    margin: 0 8px 2px 0;
}
.tag-style-text{
    font-size: 0.6rem;
}
.icon-margin-fix{
    padding-top: 2px;
}




.c-card {
    border: none;
    border-radius: 10px
}

.c-details span {
    font-weight: 300;
    font-size: 13px
}

.c-icon {
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 39px
}

.c-badge span {
    background-color: #fffbec;
    width: 60px;
    height: 25px;
    padding-bottom: 3px;
    border-radius: 5px;
    display: flex;
    color: #fed85d;
    justify-content: center;
    align-items: center
}

.c-progress {
    height: 10px;
    border-radius: 10px
}

.c-progress div {
    background-color: red
}

.c-text1 {
    font-size: 14px;
    font-weight: 600
}

.c-text2 {
    color: #a5aec0
}

.no-footer{
    padding-top:0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}



</style>