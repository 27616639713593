<template>
    <div class="pretty-tiles">
        <!-- <h6 style="font-size: 0.8rem;" v-show="hasSourceData && hasBreadcrumbData">
            <span v-for="(parent, index ) in expanded_items" :key="parent.id">
                <span class="me-2 ms-2" v-if="index > 0">
                    <font-awesome-icon icon="fa-angle-double-right"></font-awesome-icon>
                </span>
                <span class="cursor-pointer" :class="isLastItem(index)" @click="hideChildren(index)">
                    {{ parent.name }}
                </span>
            </span>
        </h6> -->
        <!-- <div id="tileContainer" class="bg-secondary bg-soft tile-scroll-container"  style="justify-content: flex-start; overflow-x: auto; width: 100%;" :class="hasParentSelected ? '' : 'd-flex'" >
            <TileCard
                v-for="item in card_data" :key="item.id"
                :id="item.id"
                :item="item"
                :depth=Number(0)
                :badge_class="badge_class"
                :webix_uid="webix_uid"
                :expanded_items="expanded_items"
                :inner_card_component_name="inner_card_component_name"
                :card_title_prefix="card_title_prefix"
                @handleToggleChild="handleToggleChild"
                @showNotesModal="showNotesModal"
            >

            </TileCard> 
        </div>-->
        <!-- //eslint-disable-next-line -->
        <transition-group name="fade">
        <div v-for="(row, index) in expandedDataRows" :key="row.id" class="outerContainer" >
            <transition-group  name="fade" class="newTileContainer">
                <TileCard
                    v-for="item in getCardData(row.id)" :key="item.id"
                    :id="item.id"
                    :item="item"
                    :depth=Number(index)
                    :badge_class="badge_class"
                    :webix_uid="webix_uid"
                    :expanded_items="expanded_items"
                    :card_expanded="card_expanded"
                    :inner_card_component_name="inner_card_component_name"
                    :card_title_prefix="card_title_prefix"
                    @handleToggleChild="handleToggleChild"
                    @showNotesModal="showNotesModal"
                    @resetSelection="$emit('resetSelection')"
                >
                </TileCard> 
            </transition-group>
        </div>
        </transition-group>
    </div>
</template>

<script>
import '@/design/css/kanban.css'
import { debounce } from 'lodash'
import { webixTableState } from '@/state/helpers'
import TileCard from '@/components/objectives/components/tiles/tileCardModern.vue'//'@/components/objectives/components/tiles/objectiveTitle.vue'
export default {
    components:{
        TileCard,
    },
    props:{
        card_data:{
            default: () => {
                return [];
            }
        },
        card_title_prefix:{
            default: '',
        },
        badge_class:{ default: ''},

        has_nested_data: {
            default: false,
        },
        webix_uid:{
            required: true
        },
        inner_card_component_name:{
            default: false,
        }
    },
    data:() => ({
        expanded_items: [],
        debug_arr:[],
    }),
    watch:{
        selected_item_id(){
            //this.$nextTick(()=>{
                this.debounceIdSelect();
            //})
        },
        card_data(){
            if(this.has_nested_data){
                this.debounceIdSelect();
            }
        }
    },
    computed:{
        ...webixTableState,
        card_expanded(){
            return this.expanded_card_ids.includes(this.webix_uid);
            //return false;
        },
        hasParentSelected(){
            //hardcoded depth of 0 because it is the root view
            return this.expanded_items[0] != undefined;
        },
        hasBreadcrumbData(){
            return this.expanded_items.length > 0;
        },
        hasSourceData(){
            return this.card_data.length > 0
        },
        selected_item_id(){
            if(this.active_card_ids[this.webix_uid] != undefined){
                return this.active_card_ids[this.webix_uid];
            }
            else{
                return -1;
            }
        },
        expandedDataRows(){
            let rows = [{
                id: 0,
            }];
            this.expanded_items.forEach( item => {
                rows.push({id: item.id});
            })
            
            return rows;
        },
    },
    methods:{
        getCardData(row_id){
            if(row_id == 0){
                //* top level just needs the top items
                return this.card_data;
            }
            else{
                //return this.findChildItems(row_id);
                let found_item = this.findItemForID(this.card_data, row_id)
                return (found_item && found_item.data) ? found_item.data : [];
            }
        },
        findItemForID(array, item_id) {
            for (const item of array) {
                if (item.id === item_id) {
                    return item;
                }

                if (item.data && Array.isArray(item.data)) {
                    const foundInChildren = this.findItemForID(item.data, item_id);
                    if (foundInChildren) {
                        return foundInChildren;
                    }
                }
            }

            return null;
        },
        isLastItem(index){
            let css_style = "text-grey";
            if(this.expanded_items.length == 0){
                return css_style;
            }
            //* return style for last item
            if((this.expanded_items.length-1) == index){
                css_style = 'text-primary'
            }
            return css_style;
        },
        debounceIdSelect: debounce( function(){
            this.handleIdSelect();
        }, 100),
        handleIdSelect(){
            // let updated_expanded_array = []
            // //* only nestable items should be setting the expanded items array
            // if(this.selected_item_id != -1 && this.has_nested_data && window.webix.$$(this.webix_uid)){
            //     let item = window.webix.$$(this.webix_uid).getItem(this.selected_item_id);
            //     if(item == undefined){
            //         //* stop here if item is undefined
            //         return;
            //     }
            //     let parent_id = window.webix.$$(this.webix_uid).data.getParentId(item.id);
            //     while(parent_id != 0){
            //         item = window.webix.$$(this.webix_uid).getItem(parent_id);
            //         updated_expanded_array.push({
            //             id: item.id,
            //             name: item.name
            //         })
            //         parent_id = window.webix.$$(this.webix_uid).data.getParentId(item.id);
            //     }
            //     this.expanded_items = updated_expanded_array.reverse();
            // }
            this.scrollToCard();
        },
        ///handleIdSelect:debounce(function(){
        ///    
        ///    }
        ///}, 1000),
        handleToggleChild(payload){
            if(this.expanded_items[payload.depth_index] && this.expanded_items[payload.depth_index].id == payload.item.id){
                let cut_array = [];
                this.expanded_items.forEach( (crumb_item, index) => {
                    if(index < payload.depth_index){
                        cut_array.push(crumb_item);
                    }
                })
                this.expanded_items = cut_array;
                //this.expanded_items.length = payload.depth_index;
                return;
            }
            //const depth_index = payload.depth_index;
            if(payload.depth_index == 0){
                const item = payload.item;
                this.expanded_items = [item];
            }
            else{
                let cut_array = [];
                this.expanded_items.forEach( (crumb_item, index) => {
                    if(index < payload.depth_index){
                        cut_array.push(crumb_item);
                    }
                })
                cut_array.push(payload.item)
                this.expanded_items = cut_array;
            }
            
        },
        hideChildren(index){
            let new_array = [];
            this.expanded_items.forEach( (item, list_index) => {
                console.table({
                    index: index, list_index: list_index
                });
                if(list_index < index){
                    new_array.push(item);
                }
            })
            this.expanded_items = new_array;
        },
        scrollToCard(id = this.selected_item_id){
            if(id == -1){
                return;
            }
            let element = document.getElementById(id);
            if (element) {
                this.$nextTick(()=>{
                    element.scrollIntoView({ behavior: 'instant', block: "nearest", inline: "nearest" });
                })
            }
        },
        showNotesModal(event_data){
            this.$emit('showNotesModal', event_data);
        },
    },
}
</script>

<style>
.tile-scroll-container{
    /* background-color: rgba(211, 211, 211, 0.427); */
}
.newTileContainer{
    display: flex;
    overflow-x: auto;
    background: #efefef;
    border: 0;
    /* border-radius: 5px; */
}
.newTile{
    border: 1px solid #d1d1d1;
    flex: 0 0 18rem;
    border-radius: 6px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.25);
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  */
}

</style>