<template>
    <b-modal @ok="saveNote" @hidden="resetModal" title="Edit Note" id="tableNotesModal">
        <div>
            <b-form-group :label="note_data.title">
                <b-form-textarea v-model="editedNote"></b-form-textarea>
            </b-form-group>
        </div>
        <template #modal-footer="{ ok, cancel }">
            <b-button variant="primary" @click="ok">Save</b-button>
            <b-button variant="danger" @click="cancel">Cancel</b-button>
        </template>
    </b-modal>
</template>

<script>
import {clone} from 'lodash';
export default {
    props: {
        note_data: { required: true },
    },
    data:() => ({
        editedNote: "",
    }),
    computed:{},
    methods:{
        saveNote() {
            // Emit an object containing the id and new note
            this.$emit("save", {
                id: this.note_data.id,
                notes: this.editedNote,
            });
        },
        resetModal() {
            this.editedNote = this.note_data.notes;
        },
    },
    //watch: {
    //    'note_data.notes'(newValue) {
    //        this.editedNote = newValue;
    //    },
    //},
    mounted(){
        if(this.note_data.notes){
            this.editedNote = clone(this.note_data.notes);
        }
        else{
            this.editedNote = '';
        }
        
    },
}
</script>

<style>

</style>